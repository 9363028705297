import React, { Component } from "react";
//import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

class EnquiryPage extends Component {

  componentDidMount() {

    // trackCustomEvent({
    //   category: "Conversion",
    //   action: "Evo magazine",
    //   label: "Evo traffic source"
    // });
    window.history.back();
    window.location.href="https://value.summon.com.au/";
  }

  render() {
    return (
      <p>One moment...</p>
    );
  }
}
export default EnquiryPage;


// old onsite enquiry form

// import React, { Component } from 'react';
// import Layout from "../components/Layout/Layout"
// import SEO from "../components/Seo/Seo"
// import PageHeader from '../components/Layout/PageHeader/PageHeader'
// import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
// import FlexCenter from '../components/Layout/Flex/FlexCenter'
// import Spacer from '../components/Layout/Spacer/Spacer'
// import Heading3 from '../components/Type/Heading3'
// import Paragraph1 from '../components/Type/Paragraph1'
// import ContactForm from '../components/Form/ContactForm'
// import Benefits3 from '../components/Benefits3/Benefits3'
// import CarSelector from '../components/CarSelector/CarSelector';
// import CarDisplaySummary from '../components/CarDisplaySummary/CarDisplaySummary';
// import MileageColourForm from '../components/Form/MileageColourForm';
// import InputError from '../components/Form/InputError'


// class EnquiryPage extends Component {

//     constructor(props){
//         super(props);
//         this.mileageColour = React.createRef();
//         this.state = {
//             formSubmissionSuccessful: false,
//             selectedCar: null,
//             carNotSelectedError: "",
//         }
//     }

//     selectedCarHandler = (car) => {
//         this.setState({selectedCar: car, carNotSelectedError: ""});
//     }

//     formSubmittedHandler = (car) => {
//         this.setState({formSubmissionSuccessful: true});
//     }

//     getCarDetails = () => {
//         let mc = this.mileageColour.current.getMileageColour();
//         let car = this.state.selectedCar;
//         if(car) {
//             let carDetails = [mc,car];
//             return carDetails;
//         } else {
//             this.setState({carNotSelectedError: "Please enter your car details"});
//             return null;
//         }
//     }

//     render() {

//         const confirmationMessage = (
//             <>
//              <SectionPageMargin bgColor="#f8f8f9">
//                     <FlexCenter>
//                     <Heading3>All Done!</Heading3>
//                         <Paragraph1>
//                             <strong>Thank you for your enquiry.</strong> One of our car sales consultants will be in contact shortly.
//                         </Paragraph1>
//                         <Paragraph1>
//                             You should also receive a confirmation email. Please check your spam or junk mail folder.
//                         </Paragraph1>
//                         <Paragraph1>
//                             And you can always email us at:<br />
//                             hello@summon.com.au
//                         </Paragraph1>
//                         <Spacer height="70px"/>
//                     </FlexCenter>
//                 </SectionPageMargin>
//             </>
//         )

//         return (
        
//             <Layout>
//                 <SEO    
//                     title="Summon Enquiry" 
//                     description="Summon Enquiry Form"
//                     location={this.props.location}
//                 />
        
//                 <PageHeader title="Enquire now" 
//                     text="Summon is a full concierge service for selling your prestige used car. 
//                     If your car is worth over $50,000, please complete the form or email us and 
//                     we'll be in touch to discuss your selling options and provide a free valuation. 
//                     For cars less than $50,000, we plan to have a service available for you soon!"/>
        
//                 <Benefits3 />

//                 {!this.state.formSubmissionSuccessful &&
//                     <SectionPageMargin bgColor="#f8f8f9" marginBottom="0px">
//                         <FlexCenter>
//                             <Heading3>Tell us about the car you wish to sell</Heading3>
//                             <InputError>{this.state.carNotSelectedError}</InputError>
//                         </FlexCenter>
//                     </SectionPageMargin>}

//                 {!this.state.formSubmissionSuccessful && !this.state.selectedCar && <CarSelector setCar={this.selectedCarHandler} />}
//                 {!this.state.formSubmissionSuccessful && this.state.selectedCar && <CarDisplaySummary car={this.state.selectedCar} setCar={this.selectedCarHandler}/>}
                
//                 {!this.state.formSubmissionSuccessful && <>
             
//                 <MileageColourForm ref={this.mileageColour}/>
            

//                 <SectionPageMargin  marginBottom="0px">
//                     <FlexCenter>
//                         <Heading3>Your Contact Details</Heading3>
//                         <ContactForm 
//                             formAction="car-enquiry" 
//                             getCar={this.getCarDetails}
//                             formSubmittedHandler={this.formSubmittedHandler}
//                             />
//                     </FlexCenter>
//                 </SectionPageMargin> </>
                
//                 }

//                 {this.state.formSubmissionSuccessful && confirmationMessage}

//             </Layout>
//         )
        
//     }
// }
        

// export default EnquiryPage;



